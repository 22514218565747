/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useEffect, useState, useRef } from "react";
import FreeBreakfastOutlinedIcon from '@mui/icons-material/FreeBreakfastOutlined';
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import moment from "moment";
import { useHistory } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  Table,
  Button,
  Avatar,
  Spin,
} from "antd";
import { UserOutlined, FormOutlined, CloudDownloadOutlined } from '@ant-design/icons';
import axios from 'axios';

function ViewProfile(props) {
  let history = useHistory();

  let loginData = JSON.parse(localStorage.getItem("loginData"))
  const [loading, setLoading] = useState(false);
  let N = JSON.parse(localStorage.getItem("updateTimer"))
  const [employeeData, setEmployeeData] = useState("");
  const [timeSheet, setTimeSheet] = useState("");
  const employeeID = localStorage.getItem("employeeId");
  const [getTimeData, setTimeData] = useState("")
  const [employeeTimer, setEmployeeTimer] = useState("");

  // new timer start
  const [timer, setTimer] = useState(N)


  const [startTime, setStartTime] = useState("");
  const [breakInTime, setBreakInTime] = useState("");
  const [breakOutTime, setBreakOutTime] = useState("");
  const [endTime, setEndTime] = useState("");

  // const [isActive, setIsActive] = useState(false)
  // const [isPaused, setIsPaused] = useState(false)
  // const increment = useRef(null)
  // const [timerState, setTimerState] = useState(false)
  // let getContinue = localStorage.getItem("continue")

  // useEffect(() => {
  //   if (getContinue === "true") {
  //     setTimerState(true)

  //     increment.current = setInterval(() => {
  //       setTimer((timer) => timer + 1)
  //     }, 1000)
  //   } else {
  //     handlePause()
  //   }
  // }, [])

  useEffect(() => {
    if (loginData) {
      // history.go(0)
      setLoading(true)
      getEmployeeDetails()
      if (loginData?.role === "Super-Admin") {
        getHoursData()
      }
    } else {
      history
        .push({
          pathname: '/login',
          // status: { userId: record?.id }
        })
    }


  }, [])




  const columns = [
    {
      title: "DATE",
      dataIndex: "date",
      key: "date",
      // width: "20%",
      // render: (_, record) => {
      //   console.log(_, "pp")
      //   return (
      //     <div style={{ borderWidth: 1, borderRadius: 20 }}>
      //       <img src={_} alt="" style={{ width: 60, height: 60, borderRadius: 40 }} />
      //     </div >
      //   )
      // }
    },
    {
      title: "CLOCK IN",
      dataIndex: "start_time",
      key: "start_time",
      render: (_, record) => {
        console.log(_, "pp")
        return (
          <div style={{ borderWidth: 1, borderRadius: 20 }}>
            {_ === false || _ === null ? (
              <text>-</text>
            ) : (
              <>
                {moment(_).format("LTS")}
              </>
            )}
          </div >
        )
      }
      // width: "32%",
    },
    {
      title: "BREAK IN",
      dataIndex: "break_in_time",
      key: "break_in_time",
      render: (_, record) => {
        console.log(_, "pp")
        return (
          <div style={{ borderWidth: 1, borderRadius: 20 }}>
            {_ === false || _ === null ? (
              <text>-</text>
            ) : (
              <>
                {moment(_).format("LTS")}
              </>
            )}
          </div >
        )
      }
    },
    {
      title: "BREAK OUT",
      dataIndex: "break_out_time",
      key: "break_out_time",
      render: (_, record) => {
        console.log(_, "pp")
        return (
          <div style={{ borderWidth: 1, borderRadius: 20 }}>
            {_ === false || _ === null ? (
              <text>-</text>
            ) : (
              <>
                {moment(_).format("LTS")}
              </>
            )}
          </div >
        )
      }
    },
    {
      title: "CLOCK OUT",
      key: "end_time",
      dataIndex: "end_time",
      render: (_, record) => {
        console.log(_, "pp")
        return (
          <div style={{ borderWidth: 1, borderRadius: 20 }}>
            {_ === false || _ === null ? (
              <text>-</text>
            ) : (
              <>
                {moment(_).format("LTS")}
              </>
            )}
          </div >
        )
      }
    },
    {
      title: "HOURS",
      key: "total_time",
      dataIndex: "total_time",
    },

  ];

  const getHoursData = async () => {
    const param = {
      employee_id: employeeID
    }

    await axios.post('https://api.jaxanivegfood.com/api/employee-data', param)
      .then(function (response) {
        console.log(response, "RESPONSE of Time data")
        if (response?.status) {
          console.log(response?.data, "-Sheet-")
          setTimeSheet(response?.data?.data)
          // localStorage.setItem("loginData", response?.data)
          setLoading(false)

        } else {
          console.log(response?.data?.message, "PPP");
          setLoading(false)
        }

      })
      .catch(function (error) {
        console.log(error, "EEE");
        setLoading(false)
      });
  }

  const getEmployeeDetails = async () => {

    const param = {
      user_id: employeeID
    }

    await axios.post('https://api.jaxanivegfood.com/api/get-single-employee', param)
      .then(function (response) {
        console.log(response, "RESPONSE")
        if (response?.status) {
          console.log(response?.data, "-DATATATATATATATAT-")
          // localStorage.setItem("loginData", response?.data)
          setEmployeeData(response?.data?.get_employee)
          setEmployeeTimer(response?.data?.get_employee_data)
          setLoading(false)

        } else {
          console.log(response?.data?.message, "PPP");
          setLoading(false)
        }

      })
      .catch(function (error) {
        console.log(error, "EEE");
        setLoading(false)
      });
  }

  const getStartTime = async () => {
    setLoading(true)
    var today = new Date(),

      // time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
      time = moment(today).format()

    setStartTime(time)
    let tanggal = moment(today).format('YYYY-MM-DD HH:mm:ss');
    console.log(tanggal, "OOOOTIME")

    console.log(moment(tanggal).format("LTS"), "TTTIIII")

    const param = {
      time_stamp: tanggal,
      employee_id: employeeData?.id
    }

    await axios.post('https://api.jaxanivegfood.com/api/check-in', param)
      .then(function (response) {
        console.log(response, "In Time RESPONSE")
        if (response?.status) {
          console.log(response?.data, "-IN Time-")
          getEmployeeDetails()
          // localStorage.setItem("loginData", response?.data)
          // setEmployeeData(response?.data)
          // setLoading(false)

        } else {
          console.log(response?.data?.message, "PPP");
          setLoading(false)
        }

      })
      .catch(function (error) {
        console.log(error, "EEE");
        setLoading(false)
      });


  }
  const getBreakInTime = async () => {
    setLoading(true)

    var today = new Date(),

      // time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
      time = today

    setBreakInTime(time)
    let tanggal = moment(today).format('YYYY-MM-DD HH:mm:ss');

    console.log(moment(today).format("LTS"), "TTTIIII", today.getTime())

    const param = {
      time_stamp: tanggal,
      employee_id: employeeData?.id
    }

    await axios.post('https://api.jaxanivegfood.com/api/break-in', param)
      .then(function (response) {
        console.log(response, " Break In Time RESPONSE")
        if (response?.status) {
          console.log(response?.data, "-Break In Time-")
          getEmployeeDetails()
          // localStorage.setItem("loginData", response?.data)
          // setEmployeeData(response?.data)
          // setLoading(false)

        } else {
          console.log(response?.data?.message, "PPP");
          setLoading(false)
        }

      })
      .catch(function (error) {
        console.log(error, "EEE");
        setLoading(false)
      });

  }
  const getBreakOutTime = async () => {
    setLoading(true)

    var today = new Date(),

      // time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
      time = today
    setBreakOutTime(time)
    let tanggal = moment(today).format('YYYY-MM-DD HH:mm:ss');

    console.log(moment(today).format("LTS"), "TTTIIII", today.getTime())

    const param = {
      time_stamp: tanggal,
      employee_id: employeeData?.id
    }

    await axios.post('https://api.jaxanivegfood.com/api/break-out', param)
      .then(function (response) {
        console.log(response, "Break Out Time RESPONSE")
        if (response?.status) {
          console.log(response?.data, "-Break Out Time-")
          getEmployeeDetails()
          // localStorage.setItem("loginData", response?.data)
          // setEmployeeData(response?.data)
          // setLoading(false)

        } else {
          console.log(response?.data?.message, "PPP");
          setLoading(false)
        }

      })
      .catch(function (error) {
        console.log(error, "EEE");
        setLoading(false)
      });

  }
  const getEndTime = async () => {
    setLoading(true)

    var today = new Date(),

      // time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
      time = today
    setEndTime(time)

    let tanggal = moment(today).format('YYYY-MM-DD HH:mm:ss');
    console.log(tanggal, "BREAKIN")
    // console.log(moment(today).format("LTS"), "TTTIIII", today.getTime())

    const param = {
      time_stamp: tanggal,
      employee_id: employeeData?.id
    }

    await axios.post('https://api.jaxanivegfood.com/api/check-out', param)
      .then(function (response) {
        console.log(response, "End Time RESPONSE")
        if (response?.status) {
          console.log(response?.data, "-End Time-")
          getEmployeeDetails()
          // localStorage.setItem("loginData", response?.data)
          // setEmployeeData(response?.data)
          // setLoading(false)

        } else {
          console.log(response?.data?.message, "PPP");
          setLoading(false)
        }

      })
      .catch(function (error) {
        console.log(error, "EEE");
        setLoading(false)
      });

  }

  // const exportCSV = async () => {
  //   const param = {
  //     employee_id: employeeData?.id
  //   }

  //   const headers = {
  //     'content-type': "text/csv"
  //   }
  //   console.log(employeeData?.id, "Eployee Id")
  //   await axios.post('https://api.jaxanivegfood.com/api/export', headers, param)
  //     .then(function (response) {
  //       console.log(response, " Break In Time RESPONSE")
  //       if (response?.status) {
  //         console.log(response?.data, "document")
  //         // getEmployeeDetails()
  //         // localStorage.setItem("loginData", response?.data)
  //         // setEmployeeData(response?.data)
  //         // setLoading(false)

  //       } else {
  //         console.log(response?.data?.message, "PPP");
  //         setLoading(false)
  //       }

  //     })
  //     .catch(function (error) {
  //       console.log(error, "EEE");
  //       setLoading(false)
  //     });
  // }


  return (
    <>
      <Spin spinning={loading}>
        <Col xs="24" xl={24}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title="Profile"
            // extra={
            //   <>
            //     <div>
            //       <FormOutlined style={{ fontSize: '20px', color: 'brown' }} onClick={() => alert("HI")} />
            //     </div>
            //   </>
            // }
          >
            <div style={{ padding: 30 }}>
              <div>
                <Row style={{ alignSelf: 'flex-end', justifyContent: 'space-between' }}>
                  <Row style={{ alignItems: 'center' }} >
                    <div>
                      {props?.history?.location?.status?.userDetails?.profile == "" || undefined ? (
                        <Avatar size={75} icon={<UserOutlined />} />
                      ) : (
                        <img src={employeeData?.profile} alt="avatar" style={{ width: 80, height: 80, borderRadius: 40 }} />
                      )}
                    </div>
                    <div style={{ fontSize: 20, fontWeight: "bold", marginLeft: 10 }}>{employeeData?.first_name} {employeeData?.last_name}</div>
                  </Row>
                </Row>
                <div style={{ marginTop: 20, marginBottom: 20 }}>
                  <Row style={{ justifyContent: 'space-between' }}>
                    <Col style={{ alignSelf: 'center', justifyContent: 'center' }}>
                      <Col>
                        {!employeeTimer || employeeTimer?.start_time == "" || employeeTimer?.start_time === null ? (
                          <text>-</text>
                        ) : (
                          <text>{moment(employeeTimer?.start_time).format("LTS")}</text>
                        )}
                      </Col>
                      <>
                        <Button

                          disabled={
                            employeeTimer?.start_time == null ||
                              employeeTimer?.start_time == "" ||
                              employeeTimer?.start_time == "invalid date" ?
                              false : true} onClick={() => getStartTime()} style={{
                                width: 150, backgroundColor: employeeTimer?.start_time == null ||
                                  employeeTimer?.start_time == "" ||
                                  employeeTimer?.start_time == "invalid date" ? 'brown' : '', border: 0
                              }} type="primary" icon={<LoginOutlinedIcon />} size={"large"}>
                          Clock In
                        </Button>
                      </>
                    </Col>
                    <Col style={{ alignItems: 'center' }}>
                      <Col>
                        {!employeeTimer || employeeTimer?.break_in_time == "" || employeeTimer?.break_in_time == null ? (
                          <text>-</text>
                        ) : (
                          <text>{moment(employeeTimer?.break_in_time).format("LTS")}</text>
                        )}
                      </Col>
                      <Col>
                        <Button
                          disabled={
                            employeeTimer &&
                              employeeTimer?.start_time !== null &&
                              employeeTimer?.end_time == null &&
                              employeeTimer?.break_in_time == null ||
                              employeeTimer?.break_in_time == "invalid date" ?
                              false : true} onClick={() => getBreakInTime()} style={{
                                width: 150, backgroundColor: employeeTimer &&
                                  employeeTimer?.start_time !== null &&
                                  employeeTimer?.end_time == null &&
                                  employeeTimer?.break_in_time == null ||
                                  employeeTimer?.break_in_time == "invalid date" ? 'brown' : '', border: 0
                              }} type="primary" icon={<FreeBreakfastOutlinedIcon />} size={"large"}>
                          Break In
                        </Button>
                      </Col>
                    </Col>
                    <Col style={{ alignItems: 'center' }}>
                      <Col>
                        {!employeeTimer || employeeTimer?.break_out_time == "" || employeeTimer?.break_out_time == null ? (
                          <text>-</text>
                        ) : (
                          <text>{moment(employeeTimer?.break_out_time).format("LTS")}</text>
                        )}
                      </Col>
                      <Col>
                        <Button
                          disabled={
                            employeeTimer &&
                              employeeTimer?.start_time !== null &&
                              employeeTimer?.break_in_time !== null &&
                              employeeTimer?.end_time == null &&
                              employeeTimer?.break_out_time == null ||
                              employeeTimer?.break_out_time == "invalid date" ?
                              false : true} onClick={() => getBreakOutTime()} style={{ width: 150, backgroundColor:  employeeTimer &&
                                employeeTimer?.start_time !== null &&
                                employeeTimer?.break_in_time !== null &&
                                employeeTimer?.end_time == null &&
                                employeeTimer?.break_out_time == null ||
                                employeeTimer?.break_out_time == "invalid date" ? 'brown' : '', border: 0 }} type="primary" icon={<FreeBreakfastOutlinedIcon />} size={"large"}>
                          Break Out
                        </Button>
                      </Col>
                    </Col>
                    <Col style={{ alignItems: 'center' }}>
                      <Col>
                        {!employeeTimer || employeeTimer?.end_time == "" || employeeTimer?.end_time == null ? (
                          <text>-</text>
                        ) : (
                          <text>{moment(employeeTimer?.end_time).format("LTS")}</text>
                        )}
                      </Col>
                      <Col>
                        <Button
                          disabled={
                            employeeTimer &&
                              employeeTimer?.start_time !== null &&
                              employeeTimer?.break_in_time !== null &&
                              employeeTimer?.break_out_time !== null &&
                              employeeTimer?.end_time == null ||
                              employeeTimer?.end_time == "invalid date" ?
                              false : true} onClick={() => getEndTime()} style={{ width: 150, backgroundColor:  employeeTimer &&
                                employeeTimer?.start_time !== null &&
                                employeeTimer?.break_in_time !== null &&
                                employeeTimer?.break_out_time !== null &&
                                employeeTimer?.end_time == null ||
                                employeeTimer?.end_time == "invalid date" ? 'brown' : '', border: 0 }} type="primary" icon={<LogoutOutlinedIcon />} size={"large"}>
                          Clock Out
                        </Button>
                      </Col>
                    </Col>
                  </Row>
                </div>
                <div style={{ width: "100%" }}>
                  <Row style={{ justifyContent: 'space-between', marginTop: 10 }}>
                    <div style={{ fontSize: 18, color: "#000", fontWeight: "bold" }}>
                      First Name:
                    </div>
                    <div style={{ fontSize: 18, color: "#000" }}>
                      {employeeData?.first_name}
                    </div>
                  </Row>
                  <Row style={{ justifyContent: 'space-between', marginTop: 10 }}>
                    <div style={{ fontSize: 18, color: "#000", fontWeight: "bold" }}>
                      Last Name:
                    </div>
                    <div style={{ fontSize: 18, color: "#000" }}>
                      {employeeData?.last_name}
                    </div>
                  </Row>
                  <Row style={{ justifyContent: 'space-between', marginTop: 10 }}>
                    <div style={{ fontSize: 18, color: "#000", fontWeight: "bold" }}>
                      Employee Department:
                    </div>
                    <div style={{ fontSize: 18, color: "#000" }}>
                      Chef
                    </div>
                  </Row>
                  <Row style={{ justifyContent: 'space-between', marginTop: 10 }}>
                    <div style={{ fontSize: 18, color: "#000", fontWeight: "bold" }}>
                      Email:
                    </div>
                    <div style={{ fontSize: 18, color: "#000" }}>
                      {employeeData?.email}
                    </div>
                  </Row>
                  <Row style={{ justifyContent: 'space-between', marginTop: 10 }}>
                    <div style={{ fontSize: 18, color: "#000", fontWeight: "bold" }}>
                      Mobile:
                    </div>
                    <div style={{ fontSize: 18, color: "#000" }}>
                      {employeeData?.mobile}
                    </div>
                  </Row>
                </div>
              </div>
            </div>
          </Card>
          {loginData?.role === "Super-Admin" ? (
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="Time Sheet"
              // extra={
              //   <>
              //     <Button onClick={() => exportCSV()} style={{ display: 'flex', alignItems: "center", backgroundColor: 'brown', borderColor: 'brown' }} type="primary" shape="round" icon={<CloudDownloadOutlined style={{ fontSize: '20px' }} />} size={"middle"}>Download .csv</Button>
              //   </>
              // }
            >
              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={timeSheet}
                  pagination={false}
                  className="ant-border-space"
                />
              </div>

            </Card>
          ) : null}
        </Col>
      </Spin>
    </>
  );
}

export default ViewProfile;
