import React, { useState } from 'react';
import { Button, Checkbox, Form, Input, InputNumber, Select, Upload, message } from 'antd';
import { LoadingOutlined, PlusOutlined, EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons';
import { UploadChangeParam } from 'antd/es/upload';
import { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import axios from 'axios';
import Pic from "../../../assets/images/team-4.jpg"


const { Option } = Select;
function ViewDrawer(props) {

    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState("");
    
    const [form] = Form.useForm();

    const getBase64 = (img: RcFile, callback: (url: string) => void) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    const beforeUpload = (file: RcFile) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    };

    const handleChange: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
        if (info.file.status === 'uploading') { 
            setLoading(true);
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, url => {
                setLoading(false);
                setImageUrl(url);
            });
        }
    };

    const uploadImage = ({ onSuccess }) => {

        onSuccess("ok")
    }

    console.log(imageUrl, "PPP")

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );


    const onFinish = async (values) => {
        console.log('Success:', values);

        const param = {
            first_name: values.firstname,
            last_name: values.lastname,
            department: values.department,
            profile: imageUrl,
            password: values.password,
            email: values.email,
            mobile: values.mobilenumber,
        }

        await axios.post('https://api.jaxanivegfood.com/api/add-employee', param)
            .then(function (response) {
                console.log(response, "||")
                if (response?.data?.status) {
                    onReset()
                    alert(response?.data?.message)
                } else {
                    console.log(response?.data?.message, "PPP");
                }

            })
            .catch(function (error) {
                console.log(error?.response?.data, "EEE");
            });

        console.log(param, "PARAMS")
    };

    const onReset = () => {
        form.resetFields();
    };

    return (
        <>
            <Form
                name="addUser"
                form={form}
                layout="vertical"
                initialValues={{}}
                onFinish={onFinish}
                autoComplete="off"
            >

                {/* <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    beforeUpload={beforeUpload}
                    onChange={handleChange}
                >
                    {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                </Upload> */}

                <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={true}
                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    beforeUpload={beforeUpload}
                    onChange={handleChange}
                    customRequest={uploadImage}
                >
                    {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                </Upload>

                <Form.Item
                    label="Department"
                    name="department"
                    rules={[{ required: true, message: 'Please input employee department!' }]}
                >
                    <Input placeholder='Department' />
                </Form.Item>


                <Form.Item
                    label="First Name"
                    name="firstname"
                    rules={[{ required: true, message: 'Please input employee first name!' }]}
                >
                    <Input placeholder='First Name' />
                </Form.Item>

                <Form.Item
                    label="Last Name"
                    name="lastname"
                    rules={[{ required: true, message: 'Please input employee last name!' }]}
                >
                    <Input placeholder='Last Name' />
                </Form.Item>

                <Form.Item
                    label="Email"
                    name="email"
                    rules={[{ required: true, message: 'Please input employee email!' }]}
                >
                    <Input placeholder='Email' />
                </Form.Item>

                <Form.Item
                    label="Mobile Number"
                    name="mobilenumber"
                    rules={[{ required: true, message: 'Please input employee mobile number!' }]}
                >
                    <Input placeholder='Mobile number' />
                </Form.Item>

                <Form.Item
                    label="Password"
                    name="password"
                    rules={[{ required: true, message: 'Please input employee password' }]}
                >
                    <Input.Password
                        style={{ borderRadius: 5, height: 50 }}
                        placeholder="Password"
                        iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    />
                </Form.Item>

                <Form.Item name="gender" label="Gender" rules={[{ required: true, message: "Please select a gender" }]}>
                    <Select
                        placeholder="Select a option and change input text above"
                        allowClear
                    >
                        <Option value="male">male</Option>
                        <Option value="female">female</Option>
                        <Option value="other">other</Option>
                    </Select>
                </Form.Item>

                <Form.Item>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%'
                    }}>
                        <Button type="primary" htmlType="submit" style={{backgroundColor:'brown', borderColor: 'brown'}}>
                            Submit
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </>
    );
}

export default ViewDrawer;
