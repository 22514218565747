import React, { Component }  from 'react';
import {
    Row,
    Col,
    Card,
    Radio,
    Table,
    Upload,
    message,
    Progress,
    Button,
    Avatar,
    Typography,
  } from "antd";
  import List from "../users/section/list";
  
  function Users() {

    const data = [{name:'sunil'}]
  
    return (
      <>
      <div>
        <List />
      </div>
        
      </>
    );
  }
  
  export default Users;
  