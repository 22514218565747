
import {
    Row,
    Col,
    Card,
    Radio,
    Table,
    Upload,
    message,
    Progress,
    Button,
    Avatar,
    Drawer,
    Typography,
} from "antd";
import { ToTopOutlined } from "@ant-design/icons";
import { BrowserRouter, Link, NavLink } from "react-router-dom";
import React, { useState } from "react";
// Images
import ava1 from "../../../assets/images/logo-shopify.svg";
import ava2 from "../../../assets/images/logo-atlassian.svg";
import ava3 from "../../../assets/images/logo-slack.svg";
import ava5 from "../../../assets/images/logo-jira.svg";
import ava6 from "../../../assets/images/logo-invision.svg";
import face from "../../../assets/images/face-1.jpg";
import face2 from "../../../assets/images/face-2.jpg";
import face3 from "../../../assets/images/face-3.jpg";
import face4 from "../../../assets/images/face-4.jpg";
import face5 from "../../../assets/images/face-5.jpeg";
import face6 from "../../../assets/images/face-6.jpeg";
import pencil from "../../../assets/images/pencil.svg";
import ViewDrawer from "./view";
const { Title } = Typography;

const formProps = {
    name: "file",
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    headers: {
        authorization: "authorization-text",
    },
    onChange(info) {
        if (info.file.status !== "uploading") {
            console.log(info.file, info.fileList);
        }
        if (info.file.status === "done") {
            message.success(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === "error") {
            message.error(`${info.file.name} file upload failed.`);
        }
    },
};
// table code start


function List(props) {
    const onChange = (e) => console.log(`radio checked:${e.target.value}`);
    const [open, setOpen] = useState(false);
    const [drawerData, setDrawerData] = useState({});


    // const showDrawer = (record) => {
    //     setDrawerData(record)
    //     history
    //     .push({ pathname: '/viewProfile' })
    //     // setOpen(true);
    // };

    const onClose = () => {
        setOpen(false);
    };


    const columns = [
        {
            title: "AUTHOR",
            dataIndex: "name",
            key: "name",
            width: "32%",
        },

        {
            title: "Email",
            dataIndex: "email",
            key: "email",
        },

        {
            title: "Phone",
            dataIndex: "phone",
            key: "phone",
        },

        {
            title: "Website",
            dataIndex: "website",
            key: "website",
        },
       
        {
            title: "Action",
            dataIndex: "action",
            key: 'action',
            render: (_, record) => (
                console.log("KKKK", _, "||", record)
                // <div>
                //     {/* <Button type="primary" onClick={() => showDrawer(record)}>View</Button> */}
                // </div>
            )
        },
    ];

    const newData = [{
        "id": 1,
        "name": "Leanne Graham",
        "username": "Bret",
        "email": "Sincere@april.biz",
        "address": {
            "street": "Kulas Light",
            "suite": "Apt. 556",
            "city": "Gwenborough",
            "zipcode": "92998-3874",
            "geo": {
                "lat": "-37.3159",
                "lng": "81.1496"
            }
        },
        "phone": "1-770-736-8031 x56442",
        "website": "hildegard.org",
        "company": {
            "name": "Romaguera-Crona",
            "catchPhrase": "Multi-layered client-server neural-net",
            "bs": "harness real-time e-markets"
        }
    },
    {
        "id": 2,
        "name": "Ervin Howell",
        "username": "Antonette",
        "email": "Shanna@melissa.tv",
        "address": {
            "street": "Victor Plains",
            "suite": "Suite 879",
            "city": "Wisokyburgh",
            "zipcode": "90566-7771",
            "geo": {
                "lat": "-43.9509",
                "lng": "-34.4618"
            }
        },
        "phone": "010-692-6593 x09125",
        "website": "anastasia.net",
        "company": {
            "name": "Deckow-Crist",
            "catchPhrase": "Proactive didactic contingency",
            "bs": "synergize scalable supply-chains"
        }
    },]




    return (
        <>
            <div className="tabled">
                <Row gutter={[24, 0]}>
                    <Col xs="24" xl={24}>
                        <Card
                            bordered={false}
                            className="criclebox tablespace mb-24"
                            title="Employees"
                            extra={
                                <>
                                    <Radio.Group onChange={onChange} defaultValue="a">
                                        <Radio.Button value="a">All</Radio.Button>
                                        <Radio.Button value="b">ONLINE</Radio.Button>
                                    </Radio.Group>
                                </>
                            }
                        >
                            <div className="table-responsive">
                                <Table
                                    columns={columns}
                                    dataSource={newData}
                                    pagination={false}
                                    className="ant-border-space"
                                />
                            </div>
                        </Card>

                        {/* <Card
                            bordered={false}
                            className="criclebox tablespace mb-24"
                            title="Projects Table"
                            extra={
                                <>
                                    <Radio.Group onChange={onChange} defaultValue="all">
                                        <Radio.Button value="all">All</Radio.Button>
                                        <Radio.Button value="online">ONLINE</Radio.Button>
                                        <Radio.Button value="store">STORES</Radio.Button>
                                    </Radio.Group>
                                </>
                            }
                        >
                            <div className="table-responsive">
                                <Table
                                    columns={project}
                                    dataSource={dataproject}
                                    pagination={false}
                                    className="ant-border-space"
                                />
                            </div>
                            <div className="uploadfile pb-15 shadow-none">
                                <Upload {...formProps}>
                                    <Button
                                        type="dashed"
                                        className="ant-full-box"
                                        icon={<ToTopOutlined />}
                                    >
                                        Click to Upload
                                    </Button>
                                </Upload>
                            </div>
                        </Card> */}
                    </Col>
                </Row>
            </div>

            <Drawer title="Basic Drawer" placement="right" onClose={onClose} open={open}>
                <ViewDrawer data={drawerData} />
            </Drawer>
        </>
    );
}

export default List;
