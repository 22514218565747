import React, { useState } from 'react';
import { Button, Spin, Checkbox, Form, Input, InputNumber, Select, Upload, message } from 'antd';
import { LoadingOutlined, PlusOutlined, EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons';
// import { UploadChangeParam } from 'antd/es/upload';
// import { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import axios from 'axios';
import Pic from "../../../assets/images/team-4.jpg"
import { BrowserRouter, Link, NavLink, useHistory } from "react-router-dom";


const { Option } = Select;
function ModalForm(props) {
    const [loading, setLoading] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();

    console.log(props, "MODAL")
    let history = useHistory();

    console.log(props.onSubmit, "PP")

    const onSubmit = () => {
        props.onSubmit()
    }

    const handleCancel = () => {
        props.handleCancel(false)
    }
    const [imageUrl, setImageUrl] = useState("");

    const [form] = Form.useForm();

    console.log(imageUrl, "PPP")

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    const onReset = () => {
        form.resetFields();
    };

    const onFinish = async (values) => {
        setLoading(true)
        console.log('Success:', values)

        const param = {
            user_id: props?.data,
            password: values.password
        }
        await axios.post('https://api.jaxanivegfood.com/api/profile-password', param)
            .then(function (response) {
                console.log(response, "RESPONSE|||||PAAAASSS")
                if (response?.data?.status) {
                    onSubmit()
                    setLoading(false)
                    onReset()
                } else {
                    messageApi.open({
                        type: 'error',
                        content: "Please enter valid password",
                        duration: 3,
                    });
                    onReset()
                    setLoading(false)
                    return
                }
            })
            .catch(function (error) {
                console.log(error, "EEE");
                onReset()
                setLoading(false)
            });
    };

    return (
        <>
            {contextHolder}

            <Spin spinning={loading}>
                <Form
                    name="addUser"
                    form={form}
                    layout="vertical"
                    initialValues={{}}
                    onFinish={onFinish}
                    onCancel={handleCancel}
                    autoComplete="off"
                >

                    {/* <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    beforeUpload={beforeUpload}
                    onChange={handleChange}
                >
                    {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                </Upload> */}

                    {/* <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={true}
                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    // beforeUpload={beforeUpload}
                    onChange={handleChange}
                    customRequest={uploadImage}
                >
                    {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                </Upload> */}



                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[{ required: true, message: 'Please input employee password' }]}
                    >
                        <Input.Password
                            style={{ borderRadius: 5, height: 50 }}
                            placeholder="Password"
                            iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                        />
                    </Form.Item>

                    <Form.Item>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%'
                        }}>
                            <Button type="primary" htmlType="submit" style={{ marginRight: 10, backgroundColor:'brown', borderColor: 'brown' }}>
                                Submit
                            </Button>
                        </div>
                    </Form.Item>
                </Form>
            </Spin>
        </>
    );
}

export default ModalForm;
