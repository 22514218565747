/*!
=========================================================
* Muse Ant Design Dashboard - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { Component } from 'react';
import { Switch, Route, Redirect,useHistory } from "react-router-dom";
// import Home from "./pages/Home";
import Tables from "./pages/Tables";
// import Billing from "./pages/Billing";
// import Rtl from "./pages/Rtl";
import Profile from "./pages/Profile";
import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import Main from "./components/layout/Main";
import "antd/dist/antd.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import ViewProfile from "./pages/ViewProfile";
import Users from "./pages/users";

function App() {
  let loginData = JSON.parse(localStorage.getItem("loginData"))
  let history = useHistory();
  console.log(loginData, "=====Login data")
  if (!loginData?.status || loginData == null || loginData == "") {
    console.log("comig....");
    // window.location.href = '/login'
    history.push('/login')
  } 

  
  return (
    <div className="App">
      <Switch>
        <Route
          exact
          path="/"
          render={() => {
            return (
              loginData == null || loginData == "" ?
                <Redirect to="/login" /> :
                <Redirect to="/tables" />
            )
          }}
        />
        <Route exact path="/login" component={SignIn} />
        <Main>
          {/* <Route exact path="/dashboard" component={Home} /> */}
          {/* <Route exact path="/" component={Tables} /> */}
          <Route exact path="/tables" component={Tables} />
          {/* <Route exact path="/billing" component={Billing} /> */}
          {/* <Route exact path="/rtl" component={Rtl} /> */}
          <Route exact path="/profile" component={Profile} />
          <Route exact path="/viewprofile" component={ViewProfile} />
          <Route exact path="/users" component={Users} />
          {/* <Redirect from="*" to="/dashboard" /> */}
        </Main>
        {/* <Route path="/sign-up" exact component={SignUp} /> */}

      </Switch>
    </div>
  );
}

export default App;
