import React, { useEffect, useState } from "react";

import {
  Row,
  Col,
  Card,
  Spin,
  Table,
  Form,
  Button,
  Typography,
  Modal,
  Drawer
} from "antd";
import ViewDrawer from "./users/section/view";
import axios from 'axios';
import { BrowserRouter, Link, NavLink, useHistory } from "react-router-dom";
import ModalForm from "./users/section/modalForm";

function Tables() {
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState('Content of the modal');

  let history = useHistory();
  const { Title } = Typography;
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [drawerData, setDrawerData] = useState({});
  const [viewUserId, setViewUserId] = useState("");
  const [employeeData, setEmployeeData] = useState("");
  let loginData = JSON.parse(localStorage.getItem("loginData"))

  console.log(loginData, "LOGGIN")
  // table code start

  const showModal = () => {
    setOpenModal(true);
  };


  useEffect(() => {
    if(loginData?.status){
      let N = JSON.parse(localStorage.getItem("updateTimer"))
      console.log(N, "-------&*&*&*&")
      setLoading(true)
      getEmployeeList()
    } else {
      history
        .push({
          pathname: '/login',
          // status: { userId: record?.id }
        })
    }


  }, [])


  const getEmployeeList = async () => {
    await axios.get('https://api.jaxanivegfood.com/api/get-employee')
      .then(function (response) {
        console.log(response, "RESPONSE")
        if (response?.status) {
          console.log(response?.data, "---")
          // localStorage.setItem("loginData", response?.data)
          setEmployeeData(response?.data)
          setLoading(false)

        } else {
          console.log(response?.data?.message, "PPP");
          setLoading(false)
        }

      })
      .catch(function (error) {
        console.log(error, "EEE");
        setLoading(false)
      });

  }
  const showDrawer = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    console.log('Clicked cancel button');
    setOpenModal(false);
  };


  const viewProfile = (_, record) => {

    if (loginData?.role === "Super-Admin") {
      localStorage.setItem("employeeId", record?.id)
      history
        .push({
          pathname: '/viewProfile',
          status: { userId: record?.id }
        })
      return
    } else {
      localStorage.setItem("employeeId", record?.id)
      setViewUserId(record?.id)
      showModal()
      return
    }
    // console.log(record, "P))", _)

    // setTimeout(() => {
    //   setOpenModal(false);
    //   setConfirmLoading(false);
    //   history
    //     .push({
    //       pathname: '/viewProfile',
    //       status: { userDetails: record }
    //     })
    // }, 2000);
  }

  const onClose = () => {
    setOpen(false);
  };

  const columns = [
    {
      title: "PROFILE",
      dataIndex: "profile",
      key: "profile",
      // width: "20%",
      render: (_, record) => {
        console.log(_, "pp")
        return (
          <div style={{ borderWidth: 1, borderRadius: 20 }}>
            <img src={_} alt="" style={{ width: 60, height: 60, borderRadius: 40 }} />
          </div >
        )
      }
    },
    {
      title: "NAME",
      dataIndex: "first_name",
      key: "first_name",
      width: "32%",
    },
    {
      title: "EMAIL",
      dataIndex: "email",
      key: "email",
    },

    // {
    //   title: "STATUS",
    //   key: "status",
    //   dataIndex: "status",
    // },
    {
      title: "NUMBER",
      key: "mobile",
      dataIndex: "mobile",
    },
    {
      title: "",
      key: "action",
      dataIndex: "action",
      render: (_, record) => {

        return (
          <div>
            {/* <NavLink to="/viewProfile"> */}
            <Button type="primary" style={{backgroundColor:'brown', borderColor: 'brown'}} onClick={() => viewProfile(_, record)}>View</Button>
            {/* </NavLink> */}
          </div >
        )
      }
    },
  ];

  const submitData = () => {
    console.Consolelog("LLL")
    // setOpenModal(false)
  }



  const data = [
    {
      key: '1',
      name: 'John Brown',
      email: 'john@gmail.com',
      number: '+44-9054512851',
      age: 32,
      address: 'New York Park',
    },
    {
      key: '2',
      name: 'Jim Green',
      email: 'john@gmail.com',
      number: '+44-9054512851',
      age: 40,
      address: 'London Park',
    },
  ];

  const plus = [
    <svg fill="#ffffff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px"><path d="M 12 2 C 6.4889971 2 2 6.4889971 2 12 C 2 17.511003 6.4889971 22 12 22 C 17.511003 22 22 17.511003 22 12 C 22 6.4889971 17.511003 2 12 2 z M 12 4 C 16.430123 4 20 7.5698774 20 12 C 20 16.430123 16.430123 20 12 20 C 7.5698774 20 4 16.430123 4 12 C 4 7.5698774 7.5698774 4 12 4 z M 11 7 L 11 11 L 7 11 L 7 13 L 11 13 L 11 17 L 13 17 L 13 13 L 17 13 L 17 11 L 13 11 L 13 7 L 11 7 z" /></svg>
  ]

  return (
    <Spin spinning={loading}>

      <div className="tabled">
        {/* <Row gutter={[24, 0]} xs="24" xl={24}> */}
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="Employees"
              extra={
                <>
                  {loginData?.role === "Super-Admin" ? (
                    <div>
                      <Button icon={plus} style={{backgroundColor:'brown', borderColor: 'brown'}} type="primary" onClick={() => showDrawer()}>Add Employees</Button>
                    </div>
                  ) : null}
                </>
              }
            >
              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={employeeData}
                  pagination={false}
                  className="ant-border-space"
                />
              </div>
            </Card>

            {/* <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="Projects Table"
              extra={
                <>
                  <Radio.Group onChange={onChange} defaultValue="all">
                    <Radio.Button value="all">All</Radio.Button>
                    <Radio.Button value="online">ONLINE</Radio.Button>
                    <Radio.Button value="store">STORES</Radio.Button>
                  </Radio.Group>
                </>
              }
            >
              <div className="table-responsive">
                <Table
                  columns={project}
                  dataSource={dataproject}
                  pagination={false}
                  className="ant-border-space"
                />
              </div>
              <div className="uploadfile pb-15 shadow-none">
                <Upload {...formProps}>
                  <Button
                    type="dashed"
                    className="ant-full-box"
                    icon={<ToTopOutlined />}
                  >
                    Click to Upload
                  </Button>
                </Upload>
              </div>
            </Card> */}
          </Col>
        {/* </Row> */}
        <Modal
          title="Title"
          open={openModal}
          // onOk={handleOk}
          confirmLoading={confirmLoading}
          onCancel={handleCancel}
          footer={[
          ]}
        >
          <ModalForm data={viewUserId} onSubmit={() => {
            setOpenModal(false)
            history
              .push({
                pathname: '/viewProfile',
                status: { userId: viewUserId }
              })
          }} handleCancel={handleCancel} />
        </Modal>
        <Drawer title="Add Employee" placement="right" onClose={onClose} open={open}>
          <ViewDrawer data={drawerData} />
        </Drawer>
      </div>
    </Spin>
  );
}

export default Tables;
